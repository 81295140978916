import React, {
  useEffect,
  Dispatch,
  useState,
  SetStateAction,
  useMemo,
} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import logo from "../assets/matswajp_logo.png";
import Button from "@mui/material/Button";
import axios from "axios";
import { SnackbarObject } from "../types/localTypes";
import GoogleAdsense from "../components/GoogleAdsense";
interface INickname {
  code: string;
  nickname: string;
  setNickname: Dispatch<SetStateAction<string>>;
  setStep: Dispatch<SetStateAction<number>>;
  setCode: Dispatch<SetStateAction<string>>;
  setSnackbarMessage: Dispatch<SetStateAction<SnackbarObject>>;
}
function Nickname({
  code,
  nickname,
  setNickname,
  setStep,
  setCode,
  setSnackbarMessage,
}: INickname) {
  const [participants, setParticipants] = useState<string[]>([]);
  const handleNameInput = (e: { target: { value: string } }) => {
    setNickname(e.target.value);
  };

  const fetchParticipants = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}session/get-participants/${code}`)
      .then((response) => {
        console.log("participants", response.data);
        response.data.participants.map((parti: any) => {
          setParticipants([
            ...participants,
            parti.nickname.toLocaleLowerCase(),
          ]);
        });
      })
      .catch((e) => {
        setSnackbarMessage({
          message: e.message,
          severity: "error",
          duration: 2000,
        });
        console.log(e);
      });
  };

  const handleClick = () => {
    if (!nickname) return;
    localStorage.setItem("nickname", nickname);
    const payload = {
      user: nickname,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}session/session-participant/${code}`,
        payload
      )
      .then((response) => {
        // console.log(response.data);
        setStep(3);
      })
      .catch((e) => {
        setSnackbarMessage({
          message: e.response.data.message,
          severity: "error",
          duration: 2000,
        });
        console.log(e);
      });
  };
  const handleGoBack = () => {
    setStep(1);
    setCode("");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchParticipants();
  }, []);

  const nicknameTaken = useMemo(() => {
    if (participants.includes(nickname.toLocaleLowerCase())) {
      return true;
    }
    return false;
  }, [nickname]);

  return (
    <>
      <Box sx={{ margin: 1 }}>
        <Box sx={{ marginX: "auto" }}>
          {" "}
          <img
            onClick={() => window.location.replace("/")}
            width={200}
            src={logo}
            alt="Matsvajp Logo"
          />
        </Box>{" "}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f6f6f6",
          padding: 2,
          borderRadius: 1,
        }}
      >
        <TextField
          id="outlined-basic"
          label="Ditt namn"
          placeholder="Fyll i ditt namn"
          variant="outlined"
          onChange={handleNameInput}
          value={nickname}
          sx={{ margin: 1 }}
          helperText={nicknameTaken ? "Upptaget. Välj ett annat namn" : ""}
          error={nicknameTaken}
        />
        <Button
          id="continue-to-matching-from-entering-name-ga-button"
          sx={{ margin: 1, color: "#ffffff" }}
          variant="contained"
          onClick={handleClick}
          disabled={!nickname || nicknameTaken}
          color={"success"}
        >
          Börja svajpa
        </Button>
        <Button sx={{ margin: 1 }} size="small" onClick={handleGoBack}>
          Gå tillbaka
        </Button>
      </Box>
      <GoogleAdsense adSlotId={"5460516832"} />
    </>
  );
}

export default Nickname;
