import React, {
  Dispatch,
  SetStateAction,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  maxWidth: "100%",
  bgcolor: "#f6f6f6",
  borderRadius: 1,
  boxShadow: 24,
  textAlign: "center",
  p: 4,
};

interface IStart {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function AboutAppPopup({
  open,
  setOpen,
}: IStart) {
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Modal
        onClose={handleClose}
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Button
            sx={{ marginBottom: 2 }}
            color="error"
            variant={"outlined"}
            onClick={() => setOpen(!setOpen)}
          >
            Stäng fönster
          </Button>
          <Typography
            gutterBottom
            id="modal-modal-title"
            variant="h4"
            component="h2"
          >
            Om Matsvajp
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="body1"
            component="h2"
            sx={{ textAlign: "left" }}
          >
            Rolig liten app som gör det lite kul
            att hitta vad man vill äta tillsammans
            med någon eller några. Kul att du
            också hittat hit!
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="body1"
            component="h2"
            sx={{ textAlign: "left" }}
          >
            Om du har någon fundering, så hör
            gärna av dig -{" "}
            <a href="mailto:georgios.drivas@gmail.com">
              georgios.drivas@gmail.com
            </a>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
