import { createTheme, responsiveFontSizes } from "@mui/material/styles";

// Create a theme instance.
let theme = createTheme({
  typography: {
    fontFamily: `"Raleway", sans-serif`,
  },
  shape: {
    borderRadius: 20,
  },
  palette: {
    mode: "light",
    primary: {
      main: "#26547c",
    },
    secondary: {
      main: "#ffd166",
    },
    success: {
      main: "#06d6a0",
    },
    error: {
      main: "#ef476f",
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
