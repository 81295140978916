import { Box, Button } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
interface IGoogleAdsense {
  adSlotId: string;
  fullScreen?: boolean;
}
function GoogleAdsense({ adSlotId, fullScreen = false }: IGoogleAdsense) {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {fullScreen && (
        <Dialog fullScreen open={open}>
          <Button
            id="continue-swiping-from-fullscreen-ad-ga-button"
            sx={{ margin: 1 }}
            variant="outlined"
            size="small"
            onClick={handleClose}
            color={"success"}
          >
            Fortsätt svajpa
          </Button>

          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-7792889993795184"
            data-ad-slot={adSlotId}
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </Dialog>
      )}

      {!fullScreen && (
        <Box sx={{ marginTop: "150px" }}>
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-7792889993795184"
            data-ad-slot={adSlotId}
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </Box>
      )}
    </>
  );
}

export default GoogleAdsense;
