import React, {
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Fab from "@mui/material/Fab";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Carousel from "react-material-ui-carousel";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { red } from "@mui/material/colors";
import {
  Avatar,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
const color = red[500];

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxWidth: "100%",

  bgcolor: "#f6f6f6",
  borderRadius: 1,
  boxShadow: 24,
  textAlign: "center",
  p: 4,
};

interface IMatchResultModal {
  session: any;
  open: boolean;
  coords: any;
  matches: any[];
  setOpen: Dispatch<SetStateAction<boolean>>;
}

function MatchResultModal({
  session,
  open,
  coords,
  matches,
  setOpen,
}: IMatchResultModal) {
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Fab
        id="show-matching-results-ga-button"
        variant="extended"
        onClick={() => {
          setOpen(!open);
        }}
      >
        {matches.length === 0 ? (
          <FavoriteBorderIcon sx={{ mr: 1 }} />
        ) : (
          <FavoriteIcon
            color={"error"}
            sx={{ mr: 1 }}
          />
        )}

        {matches.length === 0
          ? "Matchningar"
          : "Matchningar"}
      </Fab>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {" "}
          <Button
            sx={{ marginBottom: 4 }}
            color="error"
            variant={"outlined"}
            onClick={() => setOpen(!setOpen)}
          >
            Stäng fönster
          </Button>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h6"
          >
            {matches.length === 0
              ? "Ingen match ännu... Ha lite tålamod, övriga deltagare kanske inte har svajpat klart... Ni måste likea samma maträtt för att den ska dyka upp i listan."
              : ""}
          </Typography>
          {matches.length !== 0 && (
            <>
              <List
                dense
                sx={{
                  width: "100%",
                  maxWidth: 390,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  maxHeight: 500,
                  overflow: "scroll",
                }}
              >
                {matches.map((item) => {
                  const labelId = `match-item-${item.data.id}`;
                  return (
                    <ListItem
                      key={item.data.id}
                      secondaryAction={
                        <IconButton
                          href={item.foodUrl}
                          target="_blank"
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar
                            alt={item.data.food}
                            src={
                              item.data.foodImg
                            }
                          />
                        </ListItemAvatar>
                        <ListItemText
                          id={labelId}
                          primary={item.data.food}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
          <Button
            sx={{ margin: 2 }}
            color="success"
            onClick={() =>
              window.location.replace("/")
            }
          >
            Starta ny matchning
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default MatchResultModal;
