export const findOcc = (
  arr: any[],
  key: string
) => {
  let arr2: any[] = [];

  arr.forEach((x) => {
    // Checking if there is any object in arr2
    // which contains the key value
    if (
      arr2.some((val: any) => {
        return val[key] == x[key];
      })
    ) {
      // If yes! then increase the occurrence by 1
      arr2.forEach((k) => {
        if (k[key] === x[key]) {
          k["occurrence"]++;
        }
      });
    } else {
      // If not! Then create a new object initialize
      // it with the present iteration key's value and
      // set the occurrence to 1
      let a: any = {};
      a[key] = x[key];
      a["occurrence"] = 1;
      a["data"] = x;
      arr2.push(a);
    }
  });

  return arr2;
};

const objectsEqual = (o1: any, o2: any): any =>
  typeof o1 === "object" &&
  Object.keys(o1).length > 0
    ? Object.keys(o1).length ===
        Object.keys(o2).length &&
      Object.keys(o1).every((p) =>
        objectsEqual(o1[p], o2[p])
      )
    : o1 === o2;

export const arraysEqual = (
  a1: any[],
  a2: any[]
) =>
  a1.length === a2.length &&
  a1.every((o: any, idx: any) =>
    objectsEqual(o, a2[idx])
  );

export function shuffle(array: any[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(
      Math.random() * currentIndex
    );
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export const uniqueList = (array: any[]) => {
  const uniqueList: any[] = [];

  [...array].forEach((element) => {
    if (!uniqueList.includes(element)) {
      uniqueList.push(element);
    }
  });

  return uniqueList;
};
