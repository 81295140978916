import React, { useState, useEffect, SyntheticEvent } from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import { SnackbarObject } from "../types/localTypes";

function GlobalSnackbar({ message, severity, duration }: SnackbarObject) {
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    if (message !== "") {
      setShowSnackbar(true);
    }
    setTimeout(() => {
      setShowSnackbar(false);
    }, 6000);
  }, [message]);

  return (
    <Snackbar open={showSnackbar} autoHideDuration={duration}>
      <Alert severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default GlobalSnackbar;
