import React, { Dispatch, SetStateAction, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fab } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  maxWidth: "100%",
  bgcolor: "#f6f6f6",
  borderRadius: 1,
  boxShadow: 24,
  textAlign: "center",
  p: 4,
};

export default function AppStepsPopup() {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  return (
    <>
      <Button
        sx={{ marginBottom: 2 }}
        id="show-app-steps-popup-ga-button"
        color="secondary"
        variant={"contained"}
        onClick={() => setOpen(!open)}
      >
        Så går det till
      </Button>
      <Modal
        onClose={handleClose}
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Button
            sx={{ marginBottom: 2 }}
            color="error"
            variant={"outlined"}
            onClick={() => setOpen(!setOpen)}
          >
            Stäng fönster
          </Button>
          <Typography
            gutterBottom
            id="modal-modal-title"
            variant="h4"
            component="h2"
          >
            Så gör du
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            <Box
              sx={{
                padding: 1,
                margin: 1,
                border: "1px solid gray",
                borderRadius: "15px",
              }}
            >
              1. Välj hur många som ska vara med och bestämma vad ni ska äta
            </Box>
            {/* <Box
              sx={{
                padding: 1,
                margin: 1,
                border: "1px solid gray",
                borderRadius: "15px",
              }}
            >
              {" "}
              2. Välj om ni ska laga maten eller hitta en restaurang
            </Box> */}
            <Box
              sx={{
                padding: 1,
                margin: 1,
                border: "1px solid gray",
                borderRadius: "15px",
              }}
            >
              {" "}
              2. Ange ditt namn
            </Box>
            <Box
              sx={{
                padding: 1,
                margin: 1,
                border: "1px solid gray",
                borderRadius: "15px",
              }}
            >
              {" "}
              3. Dela matchningskoden genom att klicka på knappen
              <Button
                size="small"
                sx={{ marginLeft: 1 }}
                variant="contained"
                color="secondary"
              >
                Dela kod
              </Button>{" "}
            </Box>
            <Box
              sx={{
                padding: 1,
                margin: 1,
                border: "1px solid gray",
                borderRadius: "15px",
              }}
            >
              {" "}
              4. Börja svajpa genom maträtterna
            </Box>
            <Box
              sx={{
                padding: 1,
                margin: 1,
                border: "1px solid gray",
                borderRadius: "15px",
              }}
            >
              5. Öppna listan över dina matchningar genom att klicka på knappen{" "}
              <Fab
                sx={{ marginLeft: 1 }}
                size="small"
                id="show-matching-results-ga-button"
                variant="extended"
              >
                <FavoriteIcon sx={{ mr: 1 }} />
                Visa matchningar
              </Fab>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
