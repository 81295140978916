import React, { Dispatch, SetStateAction, useState } from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const style = {
  width: 600,
  maxWidth: "100%",
  bgcolor: "#f6f6f6",
  borderRadius: 1,
  boxShadow: 24,
  textAlign: "center",
  p: 2,
};

// interface IFaqPopup {
//   open: boolean;
//   setOpen: Dispatch<SetStateAction<boolean>>;
// }
export default function FaqPopup() {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <Button
        sx={{ marginBottom: 2 }}
        id="faq-popup-ga-button"
        color="secondary"
        variant={"contained"}
        onClick={() => setOpen(!open)}
      >
        FAQ
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Box sx={style}>
          <Button
            sx={{ marginBottom: 2 }}
            color="error"
            variant={"outlined"}
            onClick={() => setOpen(!setOpen)}
          >
            Stäng fönster
          </Button>{" "}
          <Typography id="modal-modal-title" variant="h4" component="h2">
            FAQ
          </Typography>
          <DialogContent sx={{ textAlign: "left", padding: 1 }}>
            <DialogContentText>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Vad är Matsvajp?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Vad kul att du frågar! Matsvajp är en matletartjänst på ren
                    svenska. Du svajpar genom maträtter du/ni kan laga eller äta
                    ute. Detta gör du själv eller tillsammans med den du skickar
                    din kod till. Typ partner, vänner, familj eller din katt.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Är Matsvajp gratis?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>Ja.</Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    Behöver jag skapa konto hos er för att använda Matsvajp?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>Nej.</Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Hur använder jag Matsvajp?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Enkelt. Du startar ny matchning med dina egna inställningar.
                    Välj ett användarnamn och svajpa fram de du känner för att
                    bita tänderna i och stilla hungern.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Hur delar jag min matchning?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Efter du startat din sökning. Dela kod kommer du hitta
                    längst upp. * Scanna QR kod. * Använda den unika 6-siffriga
                    koden. * Kopiera URL och dela.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    Hur många kan svajpa fram mat samtidigt?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    I dagsläget kan 8 pers svajpa i samma matchning.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    Hur många måste likea eller gilla för att det ska bli match?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Så många som ni väljer ska va med i matchningen. Dvs alla
                    som deltar måste vara med på tåget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    Hur vet jag vilka som är inne och svajpar på min lista
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Du ser alla användare som är online i svajpvyn.
                  </Typography>
                </AccordionDetails>
              </Accordion> */}
            </DialogContentText>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
}
