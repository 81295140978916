import {
  Box,
  Button,
  Dialog,
  Grid,
  Link,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, {
  Dispatch,
  SetStateAction,
  useState,
} from "react";
interface ILoginDialog {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}
function LoginDialog({
  open,
  setOpen,
}: ILoginDialog) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogin = async () => {
    if (email === "") return;
    if (password === "") return;

    try {
      const payload = {
        email: email,
        password: password,
      };

      const login = await axios.post(
        `${process.env.REACT_APP_API_URL}auth/login`,
        payload
      );
      setToken(login.data.token);
      localStorage.setItem(
        "token",
        login.data.token
      );
      console.log("login response", login);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEmailInput = (e: {
    target: { value: string };
  }) => {
    setEmail(e.target.value);
  };

  const handlePasswordInput = (e: {
    target: { value: string };
  }) => {
    setPassword(e.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <Box
        sx={{
          marginTop: 8,
          marginBottom: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 4,
          backgroundColor: "white",
          borderRadius: 1,
        }}
      >
        <Box sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleEmailInput}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Lösenord"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={handlePasswordInput}
          />

          <Button
            onClick={handleLogin}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Logga in
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Endast för admin
              </Link>
            </Grid>
            {/* <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Talk to your boss"}
                </Link>
              </Grid> */}
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
}

export default LoginDialog;
