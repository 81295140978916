import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import QRCode from "react-qr-code";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { SnackbarObject } from "../types/localTypes";
interface IShare {
  code: string;
  session: any;
  setSnackbarMessage: Dispatch<SetStateAction<SnackbarObject>>;
}

function Share({ code, session, setSnackbarMessage }: IShare) {
  const [inviteUrl, setInviteUrl] = useState(
    `${process.env.REACT_APP_BASE_URL}?code=${code}`
  );
  const [copied, setCopied] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    if (!session) return;
    if (
      session.participants &&
      session.participants.length === 1 &&
      session.numOfParticipants > 1
    ) {
      setShowOptions(true);
    }
  }, [session]);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);

    if (copied) {
      setSnackbarMessage({
        message: "Kopierad!",
        severity: "success",
        duration: 2000,
      });
    }
  }, [copied]);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    maxWidth: "100%",
    bgcolor: "#f6f6f6",
    borderRadius: 1,
    boxShadow: 24,

    p: 4,
  };
  const handleClose = () => setShowOptions(false);
  return (
    <>
      <Button
        id="share-code-ga-button"
        color="secondary"
        variant={"contained"}
        onClick={() => setShowOptions(!showOptions)}
      >
        Dela kod
      </Button>

      <Modal
        open={showOptions}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              margin: 2,
            }}
          >
            <Button
              sx={{ marginBottom: 2 }}
              color="success"
              variant={showOptions ? "outlined" : "contained"}
              onClick={() => setShowOptions(!showOptions)}
            >
              Gå till matchning
            </Button>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                margin: 1,
                textAlign: "center",
              }}
            >
              {" "}
              <Typography variant="caption" sx={{ marginX: "auto" }}>
                Matchningskod
              </Typography>
              <Typography gutterBottom variant="h3" sx={{ marginX: "auto" }}>
                {code}
              </Typography>
            </Box>

            <Typography
              sx={{
                textAlign: "left",
                margin: 1,
              }}
              variant="body1"
              component="div"
            >
              Dela matchningkoden för att fullfölja matchningen. Du kan dela din
              kod, skicka en länk eller låta övriga deltagare skanna QR med sin
              mobil.
            </Typography>
            <CopyToClipboard text={inviteUrl} onCopy={() => setCopied(true)}>
              <Button
                id="copy-code-url-ga-button"
                sx={{
                  margin: 1,
                }}
                variant={"contained"}
              >
                Kopiera länk till matchning
              </Button>
            </CopyToClipboard>
            <CopyToClipboard text={code} onCopy={() => setCopied(true)}>
              <Button
                id="copy-code-ga-button"
                sx={{
                  margin: 1,
                }}
                variant={"contained"}
              >
                Kopiera matchningskod
              </Button>
            </CopyToClipboard>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                margin: 2,
                textAlign: "center",
              }}
            >
              <Typography variant="caption" sx={{ marginX: "auto" }}>
                Skanna QR
              </Typography>

              <Box sx={{ marginX: "auto" }}>
                <QRCode value={inviteUrl} size={150} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default Share;
