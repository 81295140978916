import React from "react";
import Card from "@mui/material/Card";

import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";

interface IRestaurantCard {
  loading: boolean;
  restaurant: RestaurantObject;
}

function RestaurantCard({ loading, restaurant }: IRestaurantCard) {
  return (
    <>
      {loading && <CircularProgress size={250} />}
      {!loading && (
        <Card
          sx={{
            userSelect: "none",
            borderRadius: 1,
            position: "relative",
            height: "250",
          }}
        >
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                background:
                  "linear-gradient(rgba(255, 209, 102, 0.7), rgba(255, 209, 102,1))",

                color: "white",
                paddingLeft: 1,
                paddingRight: 1,
                paddingTop: 2,
                paddingBottom: 2,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Frank Ruhl Libre",
                  fontWeight: "700",
                }}
                variant="h3"
                component="div"
              >
                {restaurant.name}
              </Typography>
            </Box>
          </Box>
          <h1> {restaurant.name}</h1>
          <h3> {restaurant.vicinity}</h3>

          <h4>Rating: {restaurant.rating}</h4>
        </Card>
      )}
    </>
  );
}

export default RestaurantCard;
