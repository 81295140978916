import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const faqStr = `__FAQ__

__Vad är Matsvajp?__

Vad kul att du frågar! Matsvajp är en matletartjänst på ren
svenska. Du svajpar genom maträtter du/ni kan laga eller äta
ute. Detta gör du själv eller tillsammans med den du skickar
din kod till. Typ partner, vänner, familj eller din katt.

__Är Matsvajp gratis?__

Ja.

__Behöver jag skapa konto hos er för att använda Matsvajp?__

Nej.

__Hur använder jag Matsvajp?__

Enkelt. Du startar ny matchning med dina egna inställningar.
Välj ett användarnamn och svajpa fram de du känner för att
bita tänderna i och stilla hungern.

__Hur delar jag min matchning?__

Efter du startat din sökning. Dela kod kommer du hitta längst upp. * Scanna QR kod. * Använda den unika 6-siffriga koden. * Kopiera URL och dela.

__Hur många kan svajpa fram mat samtidigt?__

I dagsläget kan 8 pers svajpa i samma matchning.

__Hur många måste likea eller gilla för att det ska bli match?__

Så många som ni väljer ska va med i matchningen. Dvs alla som deltar måste vara med på tåget.
`;

function FAQ() {
  return <ReactMarkdown children={faqStr} remarkPlugins={[remarkGfm]} />;
}

export default FAQ;
