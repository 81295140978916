import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { useGeolocated } from "react-geolocated";
import {
  useSpring,
  animated,
} from "react-spring";
import logo from "../assets/matswajp_logo.png";
import { Fab, Action } from "react-tiny-fab";
import MenuIcon from "@mui/icons-material/Menu";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import "react-tiny-fab/dist/styles.css";
import "../App.css";
import Matching from "./Matching";
import Start from "./Start";
import Nickname from "./Nickname";
import AboutAppPopup from "../components/AboutAppPopup";
import FaqPopup from "../components/FaqPopup";
import LoginDialog from "../components/LoginDialog";
import Footer from "../components/Footer";
import { SnackbarObject } from "../types/localTypes";
import LoginIcon from "@mui/icons-material/Login";
import HomeIcon from "@mui/icons-material/Home";
import ArticleIcon from "@mui/icons-material/Article";
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

interface IMain {
  codeURL: string | null;
  setSnackbarMessage: Dispatch<
    SetStateAction<SnackbarObject>
  >;
}

function Main({
  codeURL,
  setSnackbarMessage,
}: IMain) {
  // const { coords, isGeolocationEnabled } = useGeolocated({
  //   positionOptions: {
  //     enableHighAccuracy: false,
  //   },
  //   userDecisionTimeout: 5000,
  // });
  const [step, setStep] = useState<number>(1);
  const [nickname, setNickname] =
    useState<string>("");
  const [code, setCode] = useState<string>("");
  const [foodType, setFoodType] =
    useState<string>("recipe");
  const [foodCategory, setFoodCategory] =
    useState<string>("");
  const [
    numOfParticipants,
    setnumOfParticipants,
  ] = useState<number>(2);
  const [showSplash, setShowSplash] =
    useState<boolean>(false);
  const [showAboutApp, setShowAboutApp] =
    useState<boolean>(false);
  const [showFaq, setShowFaq] =
    useState<boolean>(false);
  const [showLogin, setShowLogin] =
    useState<boolean>(false);

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      // handleAcceptCookie();
    }
  }, []);

  const splashStyleAnimated = useSpring({
    from: { opacity: 1 },
    to: { opacity: 0 },
    delay: 800,
    config: { duration: 1000 },
  });

  useEffect(() => {
    const existingNickname =
      localStorage.getItem("nickname");
    if (existingNickname) {
      setNickname(existingNickname);
    }
    if (codeURL !== null) {
      setCode(codeURL);
    }
  }, []);

  useEffect(() => {
    const hiddenSplashScreen =
      sessionStorage.getItem("showSplashScreen");
    if (hiddenSplashScreen === null) {
      setShowSplash(true);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowSplash(false);
      sessionStorage.setItem(
        "showSplashScreen",
        "false"
      );
    }, 1800);
  }, []);

  return (
    <div className="App">
      <Fab
        alwaysShowTitle={true}
        style={{ top: 0, right: 0, zIndex: 99 }}
        icon={<MenuIcon />}
      >
        <Action
          text="Gå till start"
          onClick={() => {
            window.location.replace("/");
          }}
        >
          <HomeIcon />
        </Action>
        <Action
          text="Om appen"
          onClick={() =>
            setShowAboutApp(!showAboutApp)
          }
        >
          <InfoOutlinedIcon />
        </Action>
        <Action
          text="Logga in"
          onClick={() => setShowLogin(!showLogin)}
        >
          <LoginIcon />
        </Action>
      </Fab>
      <AboutAppPopup
        open={showAboutApp}
        setOpen={setShowAboutApp}
      />

      <LoginDialog
        open={showLogin}
        setOpen={setShowLogin}
      />
      {/* {showSplash && (
        <animated.div
          onClick={() => setShowSplash(false)}
          style={{
            display: "flex",
            justifyContent: "center",
            position: "absolute" as "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            // backgroundColor: "#26547c",
            height: "100%",
            zIndex: 999,
            padding: "50px",

            ...splashStyleAnimated,
          }}
        >
          <img
            style={{
              margin: 3,
              objectFit: "contain",
              width: "100%",
            }}
            src={logo}
            alt="Logo"
          />
        </animated.div>
      )} */}
      {step === 1 && (
        <Start
          code={code}
          coords={null}
          isGeolocationEnabled={false}
          numOfParticipants={numOfParticipants}
          setCode={setCode}
          setnumOfParticipants={
            setnumOfParticipants
          }
          setFoodType={setFoodType}
          foodType={foodType}
          setFoodCategory={setFoodCategory}
          foodCategory={foodCategory}
          setStep={setStep}
          setSnackbarMessage={setSnackbarMessage}
        />
      )}
      {step === 2 && (
        <Nickname
          code={code}
          setCode={setCode}
          nickname={nickname}
          setNickname={setNickname}
          setStep={setStep}
          setSnackbarMessage={setSnackbarMessage}
        />
      )}
      {step === 3 && (
        <Matching
          code={code}
          coords={null}
          nickname={nickname}
          setSnackbarMessage={setSnackbarMessage}
        />
      )}
      <Footer />
    </div>
  );
}

export default Main;
