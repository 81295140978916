import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useMemo,
} from "react";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import axios from "axios";
import { SnackbarObject } from "../types/localTypes";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper } from "@mui/material";
interface IAdmin {
  setSnackbarMessage: Dispatch<SetStateAction<SnackbarObject>>;
}

function Admin({ setSnackbarMessage }: IAdmin) {
  const [sessions, setSessions] = useState([]);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [type, setType] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchSessions = () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}session/get-all-sessions/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      .then((response) => {
        setSessions(response.data.sessions);
        setLoading(false);

        return response;
      })
      .catch((e) => {
        localStorage.removeItem("token");
        setLoading(false);
        setSnackbarMessage({
          message: e.message,
          severity: "error",
          duration: 2000,
        });
        console.log(e);
      });
  };
  useEffect(() => {
    fetchSessions();
  }, []);

  const handleChangeType = (e: SelectChangeEvent) => {
    setType(e.target.value as string);
  };
  const handleTitleInput = (e: { target: { value: string } }) => {
    setTitle(e.target.value);
  };
  const handleContentInput = (e: { target: { value: string } }) => {
    setContent(e.target.value);
  };

  const onChangePicture = (e: { target: { files: any } }) => {
    setFile(e.target.files[0]);
  };
  useEffect(() => {
    // console.log("image file: ", file);
  }, [file]);

  const handleCreateNewFood = () => {
    if (file === null) return;
    if (type === "") return;
    if (title === "") return;
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("image", file);
    formData.append("type", type);
    setLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}session/create-food/`,
      data: formData,
    })
      .then((response) => {
        // console.log("create new food resp", response.data);
        setLoading(false);
        setSnackbarMessage({
          message: response.data.message,
          severity: "success",
          duration: 500,
        });
      })
      .catch((e) => {
        setLoading(false);
        setSnackbarMessage({
          message: e.message,
          severity: "error",
          duration: 2000,
        });
        console.log(e);
      });
  };

  const sessionsArray = useMemo(() => {
    if (sessions.length === 0) return [];
    return sessions.map((session: any) => {
      return {
        _id: session["_id"].toString(),
        code: session["code"],
        type: session["type"],
        location: session.location
          ? JSON.stringify(session.location["coordinates"])
          : "",
        numOfParticipants: session.numOfParticipants,
        participants: session.participants.map((part: any) => part.nickname),
        createdAt: format(
          utcToZonedTime(session["createdAt"], "Europe/Stockholm"),
          "yyyyMMdd HH:mm"
        ),
      };
    });
  }, [sessions]);

  return (
    <>
      <div>
        <TextField
          id="outlined-basic"
          label="Title"
          variant="outlined"
          onChange={handleTitleInput}
          value={title}
          sx={{ margin: 1 }}
        />
        <TextField
          rows={5}
          multiline
          id="outlined-basic"
          label="Content"
          variant="outlined"
          onChange={handleContentInput}
          value={content}
          sx={{ margin: 1 }}
        />
        <FormControl sx={{ margin: 1 }}>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            sx={{ minWidth: 150 }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            label="Type"
            onChange={handleChangeType}
          >
            <MenuItem value={"recipe"}>recipe</MenuItem>
            <MenuItem value={"cousine"}>cousine</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" sx={{ margin: 1 }} component="label">
          Upload File
          <input type="file" name="image" hidden onChange={onChangePicture} />
        </Button>
        <Button
          color="success"
          onClick={handleCreateNewFood}
          variant="contained"
          sx={{ margin: 1 }}
          component="label"
          disabled={loading}
        >
          Create
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>code</TableCell>
              <TableCell align="right">createdAt</TableCell>
              <TableCell align="right">type</TableCell>
              <TableCell align="right">location</TableCell>
              <TableCell align="right">numOfParticipants</TableCell>
              <TableCell align="right">participants</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && <p>Laddar...</p>}
            {sessionsArray.map((item) => (
              <TableRow
                key={item._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {item.code}
                </TableCell>
                <TableCell align="right">{item.createdAt}</TableCell>
                <TableCell align="right">{item.type}</TableCell>
                <TableCell align="right">{item.location}</TableCell>
                <TableCell align="right">{item.numOfParticipants}</TableCell>
                <TableCell align="right">{item.participants}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Admin;
