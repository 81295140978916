import { Box, Link } from "@mui/material";
import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import logo from "../assets/matswajp_logo.png";
function Footer() {
  return (
    <>
      <Box
        sx={{
          paddingTop: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Link
          href="https://www.facebook.com/Matsvajp-104155479100455/"
          target="_blank"
        >
          <FacebookIcon />
        </Link>
        <Link
          href="https://www.instagram.com/matsvajp/"
          target="_blank"
        >
          <InstagramIcon />
        </Link>
      </Box>
      <Box
        sx={{
          paddingBottom: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ marginX: "auto" }}>
          {" "}
          <img
            width={100}
            src={logo}
            alt="Matsvajp Logo"
          />
        </Box>{" "}
      </Box>
      <Box sx={{ marginX: "auto" }}>
        {" "}
        Till minne av Mackan ❤️
      </Box>{" "}
    </>
  );
}

export default Footer;
