export const cacheImages = async (srcArray: any[]) => {
  const promises = srcArray.map((src) => {
    return new Promise<void>(function (resolve, reject) {
      const img: any = new Image();
      img.src = src;
      img.onload = resolve();
      img.onerror = reject();
    });
  });

  await Promise.all(promises);
};
