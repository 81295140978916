import React, { useState } from "react";
import Card from "@mui/material/Card";

import CardMedia from "@mui/material/CardMedia";

import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import CircularProgress from "@mui/material/CircularProgress";

import placeholderImage from "../assets/matswajp-A1.png";
interface IFoodCard {
  loading: boolean;
  food: FoodObject;
}
function FoodCard({ loading, food }: IFoodCard) {
  const [imageLoaded, setImageLoaded] =
    useState(false);

  // This function is triggered if an error occurs while loading an image
  const imageOnErrorHandler = (
    event: React.SyntheticEvent<
      HTMLImageElement,
      Event
    >
  ) => {
    event.currentTarget.src = placeholderImage;
    event.currentTarget.className = "error";
  };

  return (
    <>
      {loading && <CircularProgress size={300} />}
      {!loading && (
        <Card
          sx={{
            userSelect: "none",
            borderRadius: 1,
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 8,
              right: 8,

              zIndex: 99,
            }}
          >
            <Typography
              sx={{
                padding: "5px",
                backgroundColor:
                  "rgba(0, 0, 0, 0.6)",
                color: "white",
                borderRadius: "5px",
              }}
              variant="caption"
              component="div"
            >
              källa: {food.source}
            </Typography>
            {food.cookingTime > 9 && (
              <Typography
                sx={{
                  marginTop: "5px",
                  padding: "5px",
                  backgroundColor:
                    "rgba(0, 0, 0, 0.6)",
                  color: "white",
                  borderRadius: "5px",
                }}
                variant="caption"
                component="div"
              >
                {food.cookingTime} min
              </Typography>
            )}
          </Box>
          <Box sx={{ position: "relative" }}>
            <CardMedia
              component="img"
              height={!imageLoaded ? "0" : "350"}
              image={
                food.img ||
                food.imgFullsize ||
                food.originalSourceImg ||
                placeholderImage
              }
              alt={food.title}
              onLoad={() => setImageLoaded(true)}
              onError={(
                e: React.SyntheticEvent<
                  HTMLImageElement,
                  Event
                >
              ) =>
                (e.currentTarget.src =
                  food.originalSourceImg)
              }
            />

            {!imageLoaded && (
              <Skeleton
                variant="rectangular"
                sx={{ borderRadius: 2 }}
                width={"100%"}
                height={350}
              />
            )}

            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                background:
                  "linear-gradient(rgba(255, 209, 102, 0.7), rgba(255, 209, 102,1))",

                color: "white",
                paddingLeft: 1,
                paddingRight: 1,
                paddingTop: 2,
                paddingBottom: 2,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Frank Ruhl Libre",
                  fontWeight: "700",
                }}
                variant="h3"
                component="div"
              >
                {typeof food !== "undefined" &&
                typeof food.title !== "undefined"
                  ? food["title"]
                  : ""}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
              >
                {typeof food !== "undefined" &&
                typeof food.content !==
                  "undefined"
                  ? food["content"]
                  : ""}
              </Typography>
            </Box>
          </Box>
        </Card>
      )}
    </>
  );
}

export default FoodCard;
