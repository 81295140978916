import React, {
  useEffect,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
} from "react";

import openSocket from "socket.io-client";
import logo from "../assets/matswajp_logo.png";
import MatchPopup from "../components/MatchPopup";

import confetti from "canvas-confetti";
import axios from "axios";

import Box from "@mui/material/Box";
import {
  findOcc,
  arraysEqual,
  shuffle,
  uniqueList,
} from "../utils/matches";
import { browserNotification } from "../utils/notifications";

import Food from "../components/Food";
import Restaurant from "../components/Restaurant";

import Share from "../components/Share";
import { SnackbarObject } from "../types/localTypes";
import { Badge, Chip } from "@mui/material";
import { Link } from "react-router-dom";
interface IMatching {
  code: string;
  coords: any;
  nickname: string;
  setSnackbarMessage: Dispatch<
    SetStateAction<SnackbarObject>
  >;
}

function Matching({
  code,
  coords,
  nickname,
  setSnackbarMessage,
}: IMatching) {
  const [socket, setSocket] = useState(null);

  const [foods, setFoods] = useState<any[]>([]);

  const [connectedUsers, setConnectedUsers] =
    useState<string[]>([]);
  const [restaurants, setRestaurants] = useState<
    any[]
  >([]);
  const [session, setSession] = useState<any>({});
  const [
    currentSelections,
    setCurrentSelections,
  ] = useState<any[]>([]);
  const [likedFoods, setLikedFoods] = useState<
    any[]
  >([]);
  const [matches, setMatches] = useState<any[]>(
    []
  );
  const [showMatchAlert, setShowMatchAlert] =
    useState<boolean>(false);
  const [loading, setLoading] =
    useState<boolean>(false);

  const fetchFoods = () => {
    if (!session.foods) return;
    const payload = { foodsIds: session.foods };
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}session/all-foods/`,
        payload
      )
      .then((response) => {
        // console.log(response.data.foods);
        setFoods(response.data.foods);
        setLoading(false);
      })

      .catch((e) => {
        setSnackbarMessage({
          message: e.message,
          severity: "error",
          duration: 2000,
        });
        console.log(e);
        setLoading(false);
      });
  };
  const fetchRestaurants = () => {
    if (!session.restaurants) return;
    const payload = {
      restaurantIds: session.restaurants,
    };
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}session/get-restaurants/`,
        payload
      )
      .then((response) => {
        // console.log(response.data.foods);
        setRestaurants(response.data.restaurants);
        setLoading(false);
      })

      .catch((e) => {
        setSnackbarMessage({
          message: e.message,
          severity: "error",
          duration: 2000,
        });
        console.log(e);
        setLoading(false);
      });
  };
  const fetchSelections = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}session/get-selections/${code}`
      )
      .then((response) => {
        // console.log("session", response.data.selections);

        setCurrentSelections(
          response.data.selections
        );
      })
      .catch((e) => {
        setSnackbarMessage({
          message: e.message,
          severity: "error",
          duration: 2000,
        });
        console.log(e);
      });
  };
  const fetchSession = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}session/get-session/${code}`
      )
      .then((response) => {
        setSession(response.data.session);
        let alreadyOnlineUsers: string[] = [];
        response.data.sockets.map(
          (onlineUsr: any) =>
            alreadyOnlineUsers.push(
              onlineUsr.user
            )
        );
        setConnectedUsers(
          uniqueList([
            ...connectedUsers,
            ...alreadyOnlineUsers,
          ])
        );
        return response;
      })

      .catch((e) => {
        setSnackbarMessage({
          message: e.message,
          severity: "error",
          duration: 2000,
        });
        console.log(e);
      });
  };
  useEffect(() => {
    fetchSelections();
    fetchSession();
  }, []);

  useEffect(() => {
    if (session.type === "recipe") {
      fetchFoods();
    }

    if (session.type === "restaurants") {
      fetchRestaurants();
    }
  }, [session]);

  // useEffect(() => {
  //   const unloadCallback = (event: any) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //     return "";
  //   };

  //   window.addEventListener("beforeunload", unloadCallback);
  //   return () => window.removeEventListener("beforeunload", unloadCallback);
  // }, []);

  useEffect(() => {
    // try {
    //   let promise = Notification.requestPermission();
    //   // wait for permission
    //   console.log(Notification.permission);
    // } catch (e) {
    //   console.log(e);
    // }

    const socket: any = openSocket(
      process.env.REACT_APP_API_URL as string
    );
    socket.auth = { code, nickname };
    socket.connect();

    // socket.on("connect", () => {
    //   console.log("Socket connected", socket.connected); // true
    //   console.log(socket.id);
    // });

    // socket.on("connect", () => {
    //   console.log("Socket connected", socket.connected); // true
    //   console.log("on", socket.id);
    // });

    socket.on("selection", (data: any) => {
      if (data === null) return;

      if (data.code === code) {
        fetchSelections();
      }
      console.log("socket data", data);
    });
    socket.on("user_connected", (data: any) => {
      console.log("USER CONNECTED", data);
      setConnectedUsers([
        ...connectedUsers,
        data.user,
      ]);
    });
    socket.on(
      "user_disconnected",
      (data: any) => {
        console.log("USER DISCONNECTED", data);
        setConnectedUsers(
          [...connectedUsers].filter(
            (usr) => usr !== data.user
          )
        );
      }
    );
  }, []);

  const foodsStr = useMemo(() => {
    const foodsArray = foods.map((food: any) => {
      return {
        _id: food["_id"],
        title: food["title"],
        content: food["content"],
        url: food["url"],
        img: `${process.env.REACT_APP_API_URL}${food["compressedImageUrl"]}`,
        imgFullsize: `${process.env.REACT_APP_API_URL}${food["imageUrl"]}`,
        originalSourceImg: `${food["sourceImageUrl"]}`,
        type: food["type"],
        category: food["category"],
        cookingTime: food["cookingTime"],
        source: food["source"],
      };
    });
    return shuffle(foodsArray);
  }, [foods]);

  const restaurantStr = useMemo(() => {
    if (restaurants.length === 0) return [];
    const restArray = restaurants.map(
      (restaurant: any) => {
        return {
          _id: restaurant["_id"],
          name: restaurant["name"],
          vicinity: restaurant["vicinity"],
          rating: restaurant["rating"],
        };
      }
    );

    return shuffle(restArray);
  }, [restaurants]);

  useEffect(() => {
    const countedArray = findOcc(
      currentSelections,
      "foodUrl"
    );

    const actualMatches = countedArray.filter(
      (item) =>
        item.occurrence ===
        session.numOfParticipants
    );

    if (!arraysEqual(matches, actualMatches)) {
      setMatches(actualMatches);
    }
  }, [currentSelections]);

  useEffect(() => {
    if (matches.length > 0) {
      if (
        session.participants &&
        session.participants.length > 1
      ) {
        setShowMatchAlert(true);
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 },
        });
      }

      // browserNotification("It's a match!");

      setTimeout(() => {
        setShowMatchAlert(false);
      }, 4000);
    }
  }, [matches]);

  return (
    <>
      <Box
        sx={{
          marginBottom: 1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            margin: 1,
            padding: 1,
          }}
        >
          <Box sx={{ margin: 1 }}>
            <Box sx={{ marginX: "auto" }}>
              <img
                onClick={() =>
                  window.location.replace("/")
                }
                width={200}
                src={logo}
                alt="Matsvajp Logo"
              />
            </Box>{" "}
          </Box>
          {code.length >= 6 && (
            <Share
              code={code}
              session={session}
              setSnackbarMessage={
                setSnackbarMessage
              }
            />
          )}
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: "20px",
          }}
        >
          {connectedUsers.map((item) => (
            <Badge color="success" badgeContent=" " variant="dot">
              <Chip label={item} size="small" variant="outlined" />
            </Badge>
          ))}
        </Box> */}
      </Box>
      {session.type === "recipe" && (
        <Food
          foods={foodsStr}
          coords={coords}
          code={code}
          session={session}
          nickname={nickname}
          likedFoods={likedFoods}
          setLikedFoods={setLikedFoods}
          matches={matches}
          setSnackbarMessage={setSnackbarMessage}
          loading={loading}
        />
      )}
      {session.type === "restaurants" && (
        <Restaurant
          restaurants={restaurantStr}
          coords={coords}
          code={code}
          session={session}
          nickname={nickname}
          likedFoods={likedFoods}
          setLikedFoods={setLikedFoods}
          matches={matches}
          setSnackbarMessage={setSnackbarMessage}
          loading={loading}
        />
      )}

      <MatchPopup
        open={showMatchAlert}
        setOpen={setShowMatchAlert}
      />
    </>
  );
}

export default Matching;
