import React, { Dispatch, SetStateAction, useState } from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Rating from "@mui/material/Rating";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Slider from "@mui/material/Slider";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import axios from "axios";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import logo from "../assets/matswajp_logo.png";
import { styled } from "@mui/material/styles";
import { SnackbarObject } from "../types/localTypes";
import AppStepsPopup from "../components/AppStepsPopup";
import FaqPopup from "../components/FaqPopup";
import GoogleAdsense from "../components/GoogleAdsense";
const marksGoogleApiRadius = [
  {
    value: 1,
    label: "1 km",
  },

  {
    value: 5,
    label: "5 km",
  },
  {
    value: 10,
    label: "10 km",
  },
];

function valuetext(value: number) {
  return `${value} km`;
}

interface IStart {
  code: string;
  coords: any;
  isGeolocationEnabled: any;
  numOfParticipants: number;
  setCode: Dispatch<SetStateAction<string>>;
  setnumOfParticipants: Dispatch<SetStateAction<number>>;
  setFoodType: Dispatch<SetStateAction<string>>;
  foodType: string;
  setFoodCategory: Dispatch<SetStateAction<string>>;
  foodCategory: string;
  setStep: Dispatch<SetStateAction<number>>;
  setSnackbarMessage: Dispatch<SetStateAction<SnackbarObject>>;
}

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#ffd166",
  },
  "& .MuiRating-iconHover": {
    color: "#fac54b",
  },
});

function Start({
  code,
  coords,
  isGeolocationEnabled,
  numOfParticipants,
  setCode,
  setnumOfParticipants,
  setFoodType,
  foodType,
  setFoodCategory,
  foodCategory,
  setStep,
  setSnackbarMessage,
}: IStart) {
  const [googleApiRadius, setGoogleApiRadius] = useState<number>(2);

  const handleChangeRadius = (event: Event, newValue: number | number[]) => {
    setGoogleApiRadius(newValue as number);
  };

  const handleChangeFoodType = (
    event: React.MouseEvent<HTMLElement>,
    foodType: string
  ) => {
    setFoodType(foodType);
  };

  const handleChangeCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setFoodCategory("vegetarisk");
    } else {
      setFoodCategory("");
    }
  };

  const handleCodeInput = (e: { target: { value: string } }) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length <= 6) {
      setCode(onlyNums);
    }
  };
  const handlenumOfParticipantsInput = (e: any) => {
    setnumOfParticipants(e.target.value);
    // if (e.target.value < 2) {
    //   setnumOfParticipants(2);
    // } else {
    // }
  };
  const handleClick = () => {
    // console.log("coords", coords);
    if (code === "" || code.length !== 6) {
      const payload = {
        numOfParticipants: numOfParticipants,
        type: foodType,
        category: foodCategory,
        coords: { lat: coords?.latitude, lng: coords?.longitude },
        radius: googleApiRadius,
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}session/`, payload)
        .then((response) => {
          // console.log(response.data);
          setCode(response.data.session.code);
          setStep(2);
        })
        .catch((e) => {
          setSnackbarMessage({
            message: e.message,
            severity: "error",
            duration: 2000,
          });
          console.log(e);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}session/get-session/${code}`)
        .then((response) => {
          // console.log(response.data);
          setStep(2);
        })
        .catch((e) => {
          setSnackbarMessage({
            message: "Är du säker på att du har angivit rätt kod?",
            severity: "error",
            duration: 2000,
          });
          console.log(e);
        });
    }
  };

  return (
    <>
      <Box sx={{ margin: 1 }}>
        <Box sx={{ marginX: "auto" }}>
          {" "}
          <img width={200} src={logo} alt="Matsvajp Logo" />
        </Box>{" "}
        <Typography
          variant="h3"
          gutterBottom
          sx={{ fontWeight: "900", marginBottom: 2, marginTop: 2 }}
          component="div"
        >
          Ta reda på vad ni vill äta - svajpa och matcha maträtter, helt gratis.
        </Typography>
        <AppStepsPopup />
        {/* <YoutubeEmbed embedId="sR--eHQBjkE" /> */}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#f6f6f6",
            padding: 2,
            borderRadius: 1,
            boxShadow: code.length <= 0 ? 4 : 0,
          }}
        >
          Hur många är ni som ska äta?
          <StyledRating
            onChange={handlenumOfParticipantsInput}
            value={numOfParticipants}
            getLabelText={(value: number) => `${value} participants`}
            icon={<EmojiPeopleIcon fontSize="inherit" />}
            emptyIcon={<PersonOutlineOutlinedIcon fontSize="inherit" />}
            size="large"
            sx={{ margin: 1, marginX: "auto" }}
            max={8}
          />
          {/* <ToggleButtonGroup
            color="primary"
            value={foodType}
            exclusive
            sx={{ margin: 1, marginX: "auto" }}
            onChange={handleChangeFoodType}
            aria-label="Category"
            size="small"
          >
            <ToggleButton value="recipe">Vi vill laga mat</ToggleButton>
            <ToggleButton disabled={!isGeolocationEnabled} value="restaurants">
              Vi vill äta ute
            </ToggleButton>
          </ToggleButtonGroup> */}
          {/* {!isGeolocationEnabled &&
            "Aktivera platstjänster på din enhet för att hitta restauranger"} */}
          {foodType === "recipe" && (
            <FormGroup
              sx={{
                display: "flex",
                flexDirection: "row",
                margin: 1,
                marginX: "auto",
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={foodCategory === "vegetarisk"}
                    onChange={handleChangeCategory}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={"Bara vegetariskt"}
              />
            </FormGroup>
          )}
          {foodType === "restaurants" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",

                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  width: 200,
                }}
              >
                Restauranger inom radie
                <Slider
                  getAriaValueText={valuetext}
                  step={1}
                  valueLabelDisplay="auto"
                  value={googleApiRadius}
                  aria-label="Temperature"
                  min={1}
                  max={10}
                  marks={marksGoogleApiRadius}
                  onChange={handleChangeRadius}
                />
              </Box>
            </Box>
          )}
          <Button
            id="start-new-matching-session-ga-button"
            disabled={code.length === 6 || foodType === null}
            variant="contained"
            onClick={handleClick}
            sx={{ margin: 1 }}
            color={"success"}
          >
            Starta ny matchning
          </Button>
        </Box>

        <Typography
          sx={{ marginTop: 2, marginBottom: 1 }}
          variant="h4"
          component="div"
        >
          Har du fått en kod?
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#f6f6f6",
            padding: 2,
            borderRadius: 1,
            boxShadow: code.length > 0 ? 4 : 0,
          }}
        >
          <TextField
            autoComplete="off"
            inputProps={{ inputMode: "numeric" }}
            helperText="Ange den 6-siffriga koden här för att matcha mat tillsammans"
            id="outlined-basic"
            label="Kod"
            variant="outlined"
            onChange={handleCodeInput}
            value={code}
            sx={{
              margin: 2,

              marginX: "auto",
              maxWidth: "200px",
            }}
          />
          <Button
            id="join-existing-matching-session-ga-button"
            disabled={code.length < 6}
            variant="contained"
            onClick={handleClick}
            sx={{ margin: 1, color: "#ffffff" }}
            color={"success"}
          >
            Anslut till matchning
          </Button>
        </Box>
        <Typography sx={{ marginTop: 2 }} variant="h4" component="div">
          Vad gör du på matsvajp.se?
        </Typography>
        <Typography
          sx={{
            margin: 2,
          }}
          variant="body1"
          component="div"
        >
          Svajpa er fram till lunch eller middag. Inget konto behövs. Dela
          enkelt din unika lista med ja/nej på maträtter eller restauranger du
          är sugen på. Testa. Det är rätt så enkelt!
        </Typography>
        <FaqPopup />
        <GoogleAdsense adSlotId={"5460516832"} />
      </Box>
    </>
  );
}

export default Start;
