import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const cookieStr = `__Cookies__

__Vad är och innebär cookies?__

Precis av vad som framgår ur Användaravtalet/Användarvillkoren och likt många hemsidor använder vi teknologi vilket innefattar datafiler som placeras i din dator, mobiltelefon och andra enheter vilket gör det möjligt för oss att samla en specifik form av information från dig när du besöker eller interagerar med vår hemsida, tjänster, chatt och verktyg\. 

Konceptet med cookies varierar med tiden\. För att du bättre ska förstå syftet med denna Cookiepolicy och för att du ska känna dig säkrare när du interagerar med vår webbplats, följer viktiga saker att veta:

En cookie är en liten mängd data som skickas till din webbläsare och lagras på din dators eller mobila enhets hårddisk\. Om du inte avaktiverar eller raderar cookies, varje gång du använder samma webbläsare eller mobila enhet för att komma åt hemsidan kommer vår webbserver att meddelas om ditt besök på hemsidan och i sin tur kan vi ha kunskap om ditt besök och mönstret för din användning\. för mer information, se vår Personuppgiftspolicy\. 

En cookie är alltså en textfil som gör det möjligt att identifiera en användares kommunikationsmedel utan att lagra personuppgifter\. Det finns två olika typer av cookies, tidsbegränsade och session\. Tidsbegränsade cookies sparas under en längre tid och kan till exempel användas för att visa användaren sådant innehåll som tidigare missats\. Sessions Cookies raderas när webbsidan/webbläsare stängs ned\. Denna typ av cookies kan lagra information såsom valt språk och medför att användare inte behöver välja om detta på nytt varje gång hen klickar sig vidare på webbsidan\. Det förekommer även tredje mans cookies på webbsidan, d\.v\.s\. Cookies som är placerade av utomstående part\.

__Varför använder vi cookies?__

Vissa cookies krävs av tekniska och/eller säkerhetsskäl för att vår plattform ska fungera\. Vissa cookies är främst till för att möjliggöra grundläggande funktionalitet på vår plattform; inklusive webbanalys cookies som hjälper oss att förstå hur användare använder vår webbsida och hur vi kan förbättra deras användning\. Vi använder till exempel viktiga eller absolut nödvändiga cookies för att vi ska kunna känna igen dig när du loggar in på ditt Matsvajp\-konto\. 

Genom att använda cookies kan Matsvajp:s tjänster fungera som de är ämnat, det underlättar användning av vår tjänst och ger oss en bättre förståelse för hur vår webbplats används\. Det innebär att de tjänster vi utvecklat fungerar på det sätt som är tilltänkt och att vi kan förbättra och vidareutveckla de tjänster vi erbjuder, göra vår webbplats mer användarvänlig och rikta vår marknadsföring till relevanta mottagare\.

__Hur kan jag kontrollera cookies?__

Du kan avgöra om och hur en cookie kommer att accepteras genom att konfigurera sekretessinställningarna för webbläsaren du använder för att komma in på plattformen eller sekretessinställningarna för din enhet\. Eftersom sättet på vilket du kan vägra cookies via din webbläsares kontroller varierar från webbläsare till webbläsare, bör du besöka din webbläsares hjälpmeny för mer information\. Om du justerar sekretessinställningarna i webbläsaren kommer din mobila enhet att fortsätta samla in data om du inte justerar enhetens sekretessinställningar och vice versa\. Du har rätt att bestämma om du vill acceptera eller avvisa cookies\. Om du väljer att ställa in webbläsarens kontroller för att avvisa cookies, kan du fortfarande använda vår webbplats även om din åtkomst till vissa funktioner och delar av vår webbplats kan vara begränsad\.

Användaren måste alltså aktivt acceptera att ta emot cookies\. Lämnas ingen accept kan användaren stöta på problem på webbsidan\. En person som önskar undvika cookies eller vill bli varnad innan de lagras kan ändra detta själv i inställningarna på sin enhet\. 

__Nödvändiga cookies__

Vissa cookies är absolut nödvändiga för att möjliggöra åtkomst och användning av vår plattform\. De tillhandahåller nödvändig funktionalitet för att säkerställa korrekt prestanda, säkerhet och funktionalitet hos vår plattform\. Dessa cookies kan inte inaktiveras\.

__Statistiska cookies__

Statistiska cookies inklusive cookies ämnade för analys samlar in information i aggregerad form för att hjälpa oss att avgöra och förstå hur användare interagerar med och använder vår plattform\. Vi använder denna information för att hjälpa oss att förbättra och optimera vår plattform och dess säkerhet, för mer information, se vår personuppgifts\- och integritetspolicy\.

__Personliga\- och servicecookies__

Dessa cookies används för att komma ihåg din dator eller mobila enhet när du besöker vår webbplats\. De kommer ihåg dina registrerings\- och inloggningsuppgifter och dina inställningar och preferenser\. De används för att säkerställa att du inte behöver logga in varje gång du använder din webbläsare för att kommentera artiklar och för att säkerställa att interaktiva tjänster fungerar effektivt\. De används också för att rekommendera innehåll som vi tror att du kommer att vara intresserad av, baserat på vad du har tittat på tidigare\.

Cookies används också för att vår webbserver ska känna igen dig och automatiskt tar fram uppgifter du registrerat vid ett tidigare besök\. Vare sig det är Matsvajp eller tredje part som placerar cookies ges endast tillgång till anonymiserad information genom de cookies vi använder eller tillåter\.`;

function Cookies() {
  return <ReactMarkdown children={cookieStr} remarkPlugins={[remarkGfm]} />;
}

export default Cookies;
