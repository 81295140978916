import React, { useState, Dispatch, SetStateAction } from "react";

import Typography from "@mui/material/Typography";

import Stack from "@mui/material/Stack";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";

import MatchResultModal from "../components/MatchResultModal";
import Fab from "@mui/material/Fab";
import FavoriteIcon from "@mui/icons-material/Favorite";

import RestaurantCard from "../components/RestaurantCard";

import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { SnackbarObject } from "../types/localTypes";
import GoogleAdsense from "./GoogleAdsense";
interface IRestaurant {
  restaurants: RestaurantObject[];
  code: string;
  coords: any;
  session: any;
  nickname: string;
  likedFoods: any[];
  setLikedFoods: Dispatch<SetStateAction<any>>;
  matches: any[];
  setSnackbarMessage: Dispatch<SetStateAction<SnackbarObject>>;
  loading: boolean;
}

function Restaurant({
  restaurants,
  code,
  coords,
  session,
  nickname,
  likedFoods,
  setLikedFoods,
  matches,
  setSnackbarMessage,
  loading,
}: IRestaurant) {
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [inactivateLikeButton, setInactivateLikeButton] =
    useState<boolean>(false);

  const updatedParticipantDb = () => {
    const payload = {
      user: nickname,
      finished: true,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}session/session-participant/${code}`,
        payload
      )
      .then((response) => {
        // console.log(response.data);
      })
      .catch((e) => {
        setSnackbarMessage({
          message: e.message,
          severity: "error",
          duration: 2000,
        });
        console.log(e);
      });
  };

  const likeAction = () => {
    // console.log(foods[currentQuestion]);
    setInactivateLikeButton(true);

    const payload = {
      code: code,
      food: restaurants[currentQuestion].name,

      user: nickname,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}session/select-food`, payload)
      .then((response) => {
        setInactivateLikeButton(false);
      })
      .catch((e) => {
        setSnackbarMessage({
          message: e.message,
          severity: "error",
          duration: 2000,
        });
        console.log(e);
        setInactivateLikeButton(false);
      });

    if (currentQuestion < restaurants.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResults(true);
      updatedParticipantDb();
      setCurrentQuestion(currentQuestion + 1);
    }
    setInactivateLikeButton(false);
  };
  const dislikeAction = () => {
    // console.log(foods[currentQuestion]);

    if (currentQuestion < restaurants.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResults(true);
      updatedParticipantDb();
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  return (
    <>
      {currentQuestion < restaurants.length && (
        <>
          <RestaurantCard
            loading={loading}
            restaurant={restaurants[currentQuestion]}
          />
          <Stack
            direction="row"
            spacing={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: -2, marginBottom: 2 }}
          >
            <Fab id="dislike-ga-button" color="error" onClick={dislikeAction}>
              <ThumbDownOffAltIcon />
            </Fab>
            <Fab
              id="like-ga-button"
              color="success"
              onClick={likeAction}
              disabled={inactivateLikeButton}
            >
              <FavoriteBorderIcon />
            </Fab>
          </Stack>
          {/* <GoogleAdsense adSlotId={"8677874982"} /> */}
        </>
      )}
      {restaurants.length === 0 && <CircularProgress size={300} />}
      {currentQuestion >= restaurants.length && restaurants.length > 0 && (
        <>
          <Typography variant="h6" component="div">
            Du har svajpat igenom de närliggande restauranger som fanns att
            välja på i denna matchning...
          </Typography>
          {/* <GoogleAdsense adSlotId={"8677874982"} /> */}
          <Button
            sx={{ margin: 2 }}
            color="success"
            onClick={() => window.location.replace("/")}
          >
            Starta ny matchning
          </Button>
        </>
      )}

      <MatchResultModal
        session={session}
        open={showResults}
        coords={coords}
        setOpen={setShowResults}
        matches={matches}
      />
    </>
  );
}
export default Restaurant;
