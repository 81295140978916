import React, {
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
} from "react";

import Typography from "@mui/material/Typography";

import Stack from "@mui/material/Stack";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";

import MatchResultModal from "../components/MatchResultModal";
import Fab from "@mui/material/Fab";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FoodCard from "../components/FoodCard";
import { cacheImages } from "../utils/images";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { SnackbarObject } from "../types/localTypes";
import GoogleAdsense from "./GoogleAdsense";
import AdProfit from "./AdProfit";
interface IFood {
  foods: FoodObject[];

  code: string;
  coords: any;
  session: any;
  nickname: string;
  likedFoods: any[];
  setLikedFoods: Dispatch<SetStateAction<any>>;
  matches: any[];
  setSnackbarMessage: Dispatch<
    SetStateAction<SnackbarObject>
  >;
  loading: boolean;
}

function Food({
  foods,

  code,
  coords,
  session,
  nickname,
  likedFoods,
  setLikedFoods,
  matches,
  setSnackbarMessage,
  loading,
}: IFood) {
  const [currentQuestion, setCurrentQuestion] =
    useState<number>(0);
  const [showResults, setShowResults] =
    useState<boolean>(false);
  const [
    inactivateLikeButton,
    setInactivateLikeButton,
  ] = useState<boolean>(false);

  const showFullScreenAd = useMemo(() => {
    if (currentQuestion === 0) return false;
    const FREQ_AD_SHOW = 40;

    if (currentQuestion % FREQ_AD_SHOW === 0) {
      return true;
    } else {
      return false;
    }
  }, [currentQuestion]);

  const updatedParticipantDb = () => {
    const payload = {
      user: nickname,
      finished: true,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}session/session-participant/${code}`,
        payload
      )
      .then((response) => {
        // console.log(response.data);
      })
      .catch((e) => {
        setSnackbarMessage({
          message: e.message,
          severity: "error",
          duration: 2000,
        });
        console.log(e);
      });
  };

  const likeAction = () => {
    // console.log(foods[currentQuestion]);

    setInactivateLikeButton(true);

    const payload = {
      code: code,
      food: foods[currentQuestion].title,
      foodUrl: `https://${foods[currentQuestion].source}${foods[currentQuestion].url}`,
      foodImg: foods[currentQuestion].img,
      user: nickname,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}session/select-food`,
        payload
      )
      .then((response) => {
        // setLikedFoods([...likedFoods, payload]);
        setInactivateLikeButton(false);
      })
      .catch((e) => {
        setSnackbarMessage({
          message: e.message,
          severity: "error",
          duration: 2000,
        });
        console.log(e);
        setInactivateLikeButton(false);
      });

    if (currentQuestion < foods.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResults(true);
      updatedParticipantDb();
      setCurrentQuestion(currentQuestion + 1);
    }
    setInactivateLikeButton(false);
  };
  const dislikeAction = () => {
    // console.log(foods[currentQuestion]);

    if (currentQuestion < foods.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResults(true);
      updatedParticipantDb();
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  useEffect(() => {
    let array: string[] = [];
    foods.map((food) => {
      array.push(food.img);
    });
    cacheImages(array);
  }, [foods]);

  return (
    <>
      {currentQuestion < foods.length && (
        <>
          <FoodCard
            loading={loading}
            food={foods[currentQuestion]}
          />
          <Stack
            direction="row"
            spacing={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              marginTop: -2,
              marginBottom: 2,
            }}
          >
            <Fab
              id="dislike-ga-button"
              color="error"
              onClick={dislikeAction}
            >
              <ThumbDownOffAltIcon />
            </Fab>
            <MatchResultModal
              session={session}
              open={showResults}
              coords={coords}
              setOpen={setShowResults}
              matches={matches}
            />
            <Fab
              id="like-ga-button"
              color="success"
              onClick={likeAction}
              disabled={inactivateLikeButton}
            >
              <FavoriteBorderIcon />
            </Fab>
          </Stack>
          {/* <GoogleAdsense adSlotId={"5460516832"} /> */}
          {/* <AdProfit currentQuestion={currentQuestion} /> */}
          {/* {showFullScreenAd && (
            <GoogleAdsense adSlotId={"5460516832"} fullScreen />
          )} */}
        </>
      )}
      {foods.length === 0 && (
        <CircularProgress size={300} />
      )}
      {currentQuestion >= foods.length &&
        foods.length > 0 && (
          <>
            <Typography
              variant="h6"
              component="div"
            >
              Du har svajpat igenom de 100
              maträtter som fanns att välja på i
              denna matchning...
            </Typography>

            <MatchResultModal
              session={session}
              open={showResults}
              coords={coords}
              setOpen={setShowResults}
              matches={matches}
            />

            <GoogleAdsense
              adSlotId={"5460516832"}
            />
            <Button
              sx={{ margin: 2 }}
              color="success"
              onClick={() =>
                window.location.replace("/")
              }
            >
              Starta ny matchning
            </Button>
          </>
        )}
    </>
  );
}
export default Food;
