import React, {
  useState,
  useEffect,
} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import GlobalSnackbar from "./components/GlobalSnackbar";
import { AlertColor } from "@mui/material";
import theme from "./styles/theme";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  CacheProvider,
  EmotionCache,
} from "@emotion/react";
import createEmotionCache from "./styles/createEmotionCache";
import Main from "./views/Main";
import Admin from "./views/Admin";
import FAQ from "./views/FAQ";
import { SnackbarObject } from "./types/localTypes";
import CookieTerms from "./views/Cookies";
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";

import Button from "@mui/material/Button";

const clientSideEmotionCache =
  createEmotionCache();

function App(props: {
  emotionCache?: EmotionCache | undefined;
}) {
  const {
    emotionCache = clientSideEmotionCache,
  } = props;

  const [snackbarMessage, setSnackbarMessage] =
    useState<SnackbarObject>({
      message: "",
      severity: "info",
      duration: 5000,
    });
  const queryParams = new URLSearchParams(
    window.location.search
  );
  const codeURL = queryParams.get("code");

  const clearCookies = () => {
    document.cookie
      .split(";")
      .forEach(
        (cookie) =>
          (document.cookie = cookie
            .replace(/^ +/, "")
            .replace(
              /=.*/,
              `=;expires=${new Date(
                0
              ).toUTCString()};path=/`
            ))
      );
  };

  useEffect(() => {
    if (codeURL !== null)
      window.scrollTo(0, 4000);
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <GlobalSnackbar
            message={snackbarMessage.message}
            severity={snackbarMessage.severity}
            duration={snackbarMessage.duration}
          />
          <Routes>
            <Route
              path="/"
              element={
                <Main
                  codeURL={codeURL}
                  setSnackbarMessage={
                    setSnackbarMessage
                  }
                />
              }
            ></Route>
            <Route
              path="/admin-2022"
              element={
                <Admin
                  setSnackbarMessage={
                    setSnackbarMessage
                  }
                />
              }
            ></Route>
            <Route
              path="/faq"
              element={<FAQ />}
            ></Route>
            <Route
              path="/cookies"
              element={<CookieTerms />}
            ></Route>
            <Route
              path="*"
              element={
                <Main
                  codeURL={codeURL}
                  setSnackbarMessage={
                    setSnackbarMessage
                  }
                />
              }
            />
          </Routes>{" "}
          <CookieConsent
            location="bottom"
            buttonText="Acceptera"
            declineButtonText="Avvisa"
            enableDeclineButton
            style={{
              zIndex: 1000,
              background: "#ffd166",
              color: "#000000",
              padding: 10,
            }}
            buttonStyle={{
              borderRadius: 5,
              fontWeight: "bold",
              backgroundColor: "#e9e9e9",
              color: "#2f366c",
              fontSize: "20px",
            }}
            declineButtonStyle={{
              borderRadius: 5,

              backgroundColor: "#e9e9e9",
              color: "#2f366c",
              fontSize: "20px",
            }}
            extraCookieOptions={{
              domain: "matsvajp.se",
            }}
            acceptOnScroll
            onAccept={() => {
              Cookies.set(
                "gdpr-google-tagmanager",
                true
              );
              Cookies.set(
                "gdpr-facebook-pixel",
                true
              );
              Cookies.set(
                "gdpr-google-analytics",
                true
              );
              Cookies.set("CookieConsent", true);
            }}
            onDecline={() => {
              clearCookies();
            }}
          >
            Matsvajp.se, precis som många andra
            webbplatser, använder kakor (cookies).
            Vi använder kakor för att kunna ge dig
            en bra upplevelse när du besöker vår
            webbplats. Kakor används även för
            webbstatistik för att kunna göra
            förbättringar på webbplatsen. Du kan
            välja att godkänna att vi använder
            kakor under ditt besök genom att
            klicka på “Acceptera”. Läs vår policy
            kring
            <Link
              style={{ marginLeft: 2 }}
              to={`/cookies`}
            >
              Cookies
            </Link>
          </CookieConsent>
        </Router>{" "}
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
